<template>
    <div>
        <div v-for="item in stepContentList" :key="item.text">
            <p class="my-4" v-html="$t(`faqTab.gettingStarted.paypal.${item.text}`)" />
            <img class="w-100" :src="item.image" :alt="$t(`faqTab.gettingStarted.paypal.${item.text}`)">
        </div>
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.mind')" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            stepContentList: [
                {
                    image: require("../assets/step7.1.png"),
                    text: "signed"
                },
                {
                    image: require("../assets/step7.2.png"),
                    text: "different"
                }, 
                {
                    image: require("../assets/step7.3.png"),
                    text: "copyWallet"
                }
            ]
        }
    }
}
</script>